import { useEffect, useState } from 'react';

import { Schema } from './FeatureTabs.schema';
import { Icons } from '../../snippets/Icons';

import { Lotties } from '../../snippets/Lotties';
import { Markdown } from '../../snippets/markdown';

import { Link } from '../../snippets/Link';
import { Section } from '../../snippets';

export function FeatureTabs({ cms }) {
  const { heading, superheading, tabs, footerHeading, footerLink, footerBg } =
    cms;
  const [activeTab, setActiveTab] = useState(0);
  const defaultSeconds = 5;

  useEffect(() => {
    if (!tabs || tabs.length === 0) return () => null;
    const seconds = parseInt(tabs[activeTab].timer, 10) || defaultSeconds;
    const timer = setTimeout(() => {
      setActiveTab(activeTab === tabs.length - 1 ? 0 : activeTab + 1);
    }, seconds * 1000);
    return () => {
      clearTimeout(timer);
    };
  }, [activeTab]);

  const tabWithLargestAspectRatio = tabs?.reduce((carry, tab, index) => {
    const aspectRatio = (tab?.image?.width || 0) / (tab?.image?.height || 0);

    if (aspectRatio > carry) {
      return index;
    }
    return carry;
  }, 0);

  if (!tabs || tabs.length === 0) return null;

  return (
    <Section cms={cms}>
      <div className="px-contained py-[100px]">
        <div className="mx-auto flex max-w-7xl flex-col gap-8">
          {superheading && (
            <h3 className="sub-heading text-center text-brand">
              {superheading}
            </h3>
          )}

          {heading ? (
            <h2 className="mb-5 text-center text-white selection:bg-brand">
              {heading}
            </h2>
          ) : null}

          <div className="black-02-gradient overflow-hidden rounded-3xl md:pt-8">
            <div
              className={`pointer-events-none relative ${
                tabs[activeTab].fullWidth ? '' : 'md:mx-14'
              }`}
            >
              {tabs?.map((tab, index) => {
                const isActive = index === activeTab;

                if (tab?.lottie && isActive) {
                  return <Lotties lottie={tab?.lottie} play loop={false} />;
                }

                if (tab.image?.src && !tab?.lottie) {
                  return (
                    <img
                      className={`w-full ${
                        isActive ? 'opacity-1' : 'opacity-0'
                      } ${
                        tabWithLargestAspectRatio !== index
                          ? 'absolute inset-0'
                          : ''
                      }`}
                      key={index}
                      src={tab.image.src}
                      alt={tab.image.alt}
                    />
                  );
                }

                return null;
              })}
            </div>
          </div>

          {/* <div className="flex flex-col gap-6 md:hidden">
        {tabs.map((tab, index) => {
          return (
            <div
              key={`featureTabsMobile-${index}`}
              className="brand-light-gradient flex flex-col gap-8 overflow-hidden rounded-2xl pt-5 pl-5"
            >
              <div className="flex items-center gap-2">
                <Icons icon={tab.icon} className="h-6 w-6 text-brand" />
                <span className="font-inter text-xl font-semibold leading-6 text-black">
                  {tab.label}
                </span>
              </div>
              {tab.imageMobile?.src ? (
                <img src={tab.imageMobile.src} alt={tab.imageMobile.alt} />
              ) : null}
            </div>
          );
        })}
      </div> */}

          <nav className="flex flex-col gap-5 md:flex-row md:gap-4">
            {tabs.map((tab, index) => {
              const isActive = index === activeTab;
              return (
                <button
                  key={`featureTabDesktop-${index}`}
                  type="button"
                  className={`${
                    isActive ? 'black-05-gradient' : 'black-02-gradient'
                  } hover:black-05-gradient flex w-full flex-col gap-5 rounded-2xl p-5 transition-colors duration-300`}
                  onClick={() => setActiveTab(index)}
                >
                  <div className="my-auto flex flex-col gap-5">
                    <Icons icon={tab.icon} className="h-6 w-6 text-brand" />

                    <Markdown classes="text-left [&_*]:!my-0 font-inter text-base font-normal leading-6 text-gray-400 max-sm:max-w-[75%] [&_strong]:text-white [&_strong]:font-medium">
                      {tab.description}
                    </Markdown>
                  </div>
                </button>
              );
            })}
          </nav>

          <nav className="hidden justify-center md:flex">
            <div className="inline-flex justify-center gap-2 rounded-lg bg-[rgba(255,255,255,.10)] p-2">
              {tabs.map((tab, index) => {
                const isActive = index === activeTab;
                return (
                  <button
                    key={`featureTabDesktop-${index}`}
                    type="button"
                    className="flex w-[30px] flex-col gap-5"
                    onClick={() => setActiveTab(index)}
                  >
                    <div className="progress-bar-light progress-bar-slim mt-auto">
                      {isActive ? (
                        <span
                          className="progress-bar-inner progress-bar-slim progress-bar-animation"
                          style={{
                            animationDuration: `${
                              tab.timer || defaultSeconds
                            }s`,
                            animationIterationcount: tab?.lottie
                              ? 1
                              : 'infinite',
                          }}
                        />
                      ) : null}
                    </div>
                  </button>
                );
              })}
            </div>
          </nav>

          <Link
            className="brand-light-gradient group container hidden overflow-hidden rounded-3xl"
            href={footerLink.url || '/'}
            newTab={footerLink?.newTab}
          >
            <div
              className="flex w-full items-center bg-cover bg-right bg-no-repeat py-[51px] lg:bg-contain"
              style={{
                backgroundImage: footerBg?.src ? `url(${footerBg.src})` : null,
              }}
            >
              <div className="flex max-w-[278px] flex-col gap-4">
                {footerHeading ? (
                  <h2 className="text-[34px] leading-10 tracking-tight text-black">
                    {footerHeading}
                  </h2>
                ) : null}
                {footerLink.text ? (
                  <div className="flex items-center gap-2 text-primary-green-600">
                    <span className="text-base font-semibold">
                      {footerLink.text}
                    </span>
                    <Icons icon="arrowRight" className="h-5 w-5" />
                  </div>
                ) : null}
              </div>
            </div>
          </Link>
        </div>
      </div>
    </Section>
  );
}

FeatureTabs.displayName = 'FeatureTabs';
FeatureTabs.Schema = Schema;
