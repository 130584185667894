import { useSettings } from '@backpackjs/storefront';
import { Icons } from '../Icons';

import { Link } from '../Link';

export function DesktopRightMenu() {
  const settings = useSettings();

  return (
    <div className="hidden items-center gap-3 justify-self-end lg:flex">
      {settings?.header?.ctas?.getStarted?.url ? (
        <Link
          className="caption-2 whitespace-nowrap font-medium text-brand hover:underline"
          href={settings.header.ctas.getStarted.url}
          newTab={settings.header.ctas.getStarted?.newTab}
        >
          {settings.header.ctas.getStarted.text}
        </Link>
      ) : null}

      {settings?.header?.ctas?.demo?.url ? (
        <Link
          className="btn-outline-sm h-[35px] whitespace-nowrap px-3 text-xs font-medium leading-[18px]"
          href={settings.header.ctas.demo.url}
          newTab={settings.header.ctas.demo?.newTab}
        >
          <Icons icon="video" className="h-5 w-5" />
          {settings.header.ctas.demo.text}
        </Link>
      ) : null}

      {settings?.header?.ctas?.login?.url ? (
        <Link
          className="btn-primary-sm h-[35px] whitespace-nowrap px-3 text-xs font-medium leading-[18px]"
          href={settings.header.ctas.login.url}
          newTab={settings.header.ctas.login?.newTab}
        >
          {settings.header.ctas.login.text}
        </Link>
      ) : null}
    </div>
  );
}
