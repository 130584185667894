export const LottieSettings = {
  label: 'Lottie',
  name: 'lottie',
  description: 'Overrides Image',
  component: 'select',
  options: [
    {
      label: 'Select Lottie File',
      value: '',
    },
    {
      label: 'Visually Headless',
      value: 'visuallyHeadless',
    },
    {
      label: 'Developer Tools',
      value: 'developerTools',
    },
    {
      label: 'CRO Tools',
      value: 'croTools',
    },
    {
      label: 'Build Your Storefront',
      value: 'buildYourStorefront',
    },
    {
      label: 'Move Faster As A Team',
      value: 'moveFasterAsATeam',
    },
    {
      label: 'Pack Hydrogen Tree',
      value: 'packHydrogenTree',
    },
  ],
};
