import { Schema } from './Image.schema';
import { Icons } from '../../snippets/Icons';

import { Link } from '../../snippets/Link';
import { Section } from '../../snippets';

export function Image({ cms }) {
  const { image, imageAlt, showSource, sourceText, sourceLink } = cms;

  if (!image) return null;

  return (
    <Section cms={cms}>
      <div className="my-12">
        <figure className="flex flex-col gap-4">
          <img src={image.src} alt={imageAlt} />
          {showSource ? (
            <figcaption className="flex items-center gap-[6px] text-sm">
              <Icons icon="link" className="text-brand" />
              <span>{sourceText}</span>
              {sourceLink?.url ? (
                <Link
                  className="underline"
                  href={sourceLink?.url}
                  newTab={sourceLink?.newTab}
                >
                  {sourceLink.text}
                </Link>
              ) : null}
            </figcaption>
          ) : null}
        </figure>
      </div>
    </Section>
  );
}

Image.displayName = 'Image';
Image.Schema = Schema;
