import { useInView } from 'react-intersection-observer';
import React from 'react';
import { Icons } from '../../snippets/Icons';
import { Lotties } from '../../snippets/Lotties';

import { Link } from '../../snippets/Link';

export function FeatureStepsMain({ props }) {
  const {
    heading,
    description,
    watchVideoDescription,
    watchVideoText,
    watchVideoImage,
    watchVideoLink,
    lottie,
    image,
    imageAlt,
  } = { ...props };

  const { ref, inView } = useInView({
    rootMargin: '0px',
    triggerOnce: false,
    threshold: 0.3,
  });

  return (
    <div className="px-contained max-md:px-0">
      <div className="border-border relative mx-auto max-w-7xl pt-[60px] md:pt-[150px]">
        {/* <div className="feature-steps-main-gradient pointer-events-none" /> */}

        <div className="grid grid-cols-1 items-center gap-5 border-b px-4 pb-12 text-center text-black sm:px-8 md:gap-12 md:pb-[120px] lg:grid-cols-2 lg:px-0 lg:text-left">
          <div className="flex flex-col gap-5 md:gap-9">
            <h2 className="text-[40px] font-normal md:text-[56px]">
              {heading}
            </h2>

            <p className="text-base text-[rgba(0,0,0,.7)] md:text-[20px]">
              {description}
            </p>

            <div className="hidden items-center gap-5 lg:flex">
              <Link
                className="relative flex w-[100px] items-center justify-center overflow-hidden rounded-lg"
                href={watchVideoLink?.url || ''}
                newTab={watchVideoLink?.newTab}
              >
                <img src={watchVideoImage?.src} alt={watchVideoText} />
                <div className="absolute inset-0 h-full w-full bg-[rgba(0,0,0,0.3)]" />
                <Icons
                  icon="playButton"
                  className="absolute z-[1] h-8 w-8 text-brand"
                />
              </Link>

              <div className="flex flex-col">
                <p className="text-base text-[rgba(0,0,0,.6)]">
                  {watchVideoText}
                </p>

                <p className="text-base text-[rgba(0,0,0,.5)]">
                  {watchVideoDescription}
                </p>
              </div>
            </div>
          </div>

          <div ref={ref}>
            {lottie ? (
              <div>
                {inView && <Lotties lottie={lottie} play loop={false} />}
              </div>
            ) : (
              <picture>
                <img src={image?.src} alt={imageAlt} />
              </picture>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

FeatureStepsMain.displayName = 'FeatureStepsMain';
