import { Accordions } from './Accordions';
import { ArticleFooterActions } from './ArticleFooterActions';
import { ArticleHeaderHeading } from './ArticleHeaderHeading';
import { ArticleHeaderImage } from './ArticleHeaderImage';
import { ArticleSidebarNewsletter } from './ArticleSidebarNewsletter';
import { BlogGrid } from './BlogGrid';
import { BlogNavigation } from './BlogNavigation';
import { ComparePlans } from './ComparePlans';
import { ContactForm } from './ContactForm';
import { EmailSignup } from './EmailSignup';
import { FeatureAccordion } from './FeatureAccordion';
import { FeatureCarousel } from './FeatureCarousel';
import { FeatureEmailSignup } from './FeatureEmailSignup';
import { FeatureGrid } from './FeatureGrid';
import { FeatureHalfHero } from './FeatureHalfHero';
import { FeatureImage } from './FeatureImage';
import { FeatureImageInsights } from './FeatureImageInsights';
import { FeatureSteps } from './FeatureSteps';
import { FeatureTabs } from './FeatureTabs';
import { FeatureTimeline } from './FeatureTimeline';
import { FeaturedArticle } from './FeaturedArticle';
import { Html } from './Html';
import { Image } from './Image';
import { ImageQuote } from './ImageQuote';
import { LogoMarquee } from './LogoMarquee';
import { MainHero } from './MainHero';
import { Markdown } from './Markdown';
import { Metrics } from './Metrics';
import { PlanHero } from './PlanHero';
import { Quote } from './Quote';
import { SimplePageHeader } from './SimplePageHeader';
import { TestimonialBlock } from './TestimonialBlock';
import { TestimonialCarousel } from './TestimonialCarousel';
import { TestimonialMasonry } from './TestimonialMasonry';
import { TextHeading } from './TextHeading';
import { TwoColumnText } from './TwoColumnText';
import { ValueProps } from './ValueProps';
import { VideoHero } from './VideoHero';

export default [
  Accordions,
  ArticleFooterActions,
  ArticleHeaderHeading,
  ArticleHeaderImage,
  ArticleSidebarNewsletter,
  BlogGrid,
  BlogNavigation,
  ComparePlans,
  ContactForm,
  EmailSignup,
  FeatureAccordion,
  FeatureCarousel,
  FeatureEmailSignup,
  FeatureGrid,
  FeatureHalfHero,
  FeatureImage,
  FeatureImageInsights,
  FeatureSteps,
  FeatureTabs,
  FeatureTimeline,
  FeaturedArticle,
  Html,
  Image,
  ImageQuote,
  LogoMarquee,
  MainHero,
  Markdown,
  Metrics,
  PlanHero,
  Quote,
  SimplePageHeader,
  TestimonialBlock,
  TestimonialCarousel,
  TestimonialMasonry,
  TextHeading,
  TwoColumnText,
  ValueProps,
  VideoHero,
];
