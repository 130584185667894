import { useEffect, useState } from 'react';
import * as Accordion from '@radix-ui/react-accordion';
import { useRouter } from '@backpackjs/storefront';
import { Icons } from '../Icons';
import { MenuItems } from './menu-items';

import { Link } from '../Link';

export function MobileMenu({ promobarVisibleHeight, settings }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const router = useRouter();

  const handleMenuOpen = () => {
    setMenuOpen((state) => !state);

    if (menuOpen) {
      document.body.classList.remove('scroll-lock');
    } else {
      document.body.classList.add('scroll-lock');
    }
  };

  useEffect(() => {
    setMenuOpen(false);
    document.body.classList.remove('scroll-lock');
  }, [router]);

  return (
    <div className="block lg:hidden">
      <button
        type="button"
        className="h-6 w-6 border-none bg-transparent"
        onClick={handleMenuOpen}
      >
        {menuOpen ? <Icons icon="x" /> : <Icons icon="hamburger" />}
      </button>

      {/* Mobile Slide Menu */}
      <div
        style={{
          top: `${promobarVisibleHeight + 72}px`,
        }}
        className={`fixed inset-0 overflow-scroll bg-black py-6 transition-transform ease-in-out no-scrollbar ${
          menuOpen ? 'translate-x-0' : 'translate-x-full'
        }
        `}
      >
        <div className="container">
          <Accordion.Root type="single" collapsible className="space-y-2">
            {settings?.map((menuItem, index) => {
              const type = menuItem?._template;
              const title = menuItem?.title;
              if (type === 'link') {
                return (
                  <Accordion.Item key={`mobileMenu-${index}`} value={title}>
                    <Link
                      className="body-1-em inline-block py-3 text-xl font-semibold leading-[150%]"
                      href={menuItem?.link?.url || '#'}
                      newTab={menuItem?.link?.newTab}
                    >
                      {title}
                    </Link>
                  </Accordion.Item>
                );
              }

              if (type === 'menu') {
                const menuItems = menuItem?.megaMenuItems;

                return (
                  <Accordion.Item key={`mobileMenu-${index}`} value={title}>
                    <Accordion.AccordionTrigger className="AccordionTrigger">
                      <span className="body-1-em py-3 text-xl font-semibold leading-[150%]">
                        {title}
                      </span>
                      <Icons
                        icon="chevronDown"
                        className="AccordionChevron h-5 w-5"
                      />
                    </Accordion.AccordionTrigger>

                    <Accordion.AccordionContent className="space-y-6 py-4">
                      <MenuItems menuItems={menuItems} isMobile />
                    </Accordion.AccordionContent>
                  </Accordion.Item>
                );
              }

              return null;
            })}
          </Accordion.Root>
        </div>
      </div>
    </div>
  );
}
