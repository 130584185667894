import { Icons } from '../../snippets/Icons';
import { Schema } from './ValueProps.schema';

import { Section } from '../../snippets';

export function ValueProps({ cms }) {
  const { heading, valueProps = [] } = cms;
  return (
    <Section cms={cms}>
      <div className="px-contained relative pt-[100px] pb-[150px]">
        {heading && (
          <h2 className="h2 mb-20 text-center tracking-tight selection:bg-brand">
            {heading}
          </h2>
        )}
        <ul className="mx-auto grid max-w-[1100px] gap-8 sm:grid-cols-2 lg:grid-cols-4 lg:gap-12">
          {valueProps.map(
            (
              {
                heading: valuePropHeading,
                isBeta,
                isComingSoon,
                description,
                icon,
              },
              index
            ) => {
              return (
                <li
                  className="mx-auto flex flex-col items-center gap-4 text-center max-lg:max-w-[235px] md:items-start md:text-left"
                  key={index}
                >
                  <Icons icon={icon} className="h-6 w-6 text-brand" />
                  <div className="flex gap-3">
                    <p className="flex-1">{valuePropHeading}</p>
                    {(isComingSoon || isBeta) && (
                      <div className="relative -top-[2px]">
                        <span className="black-02-gradient rounded-2xl border border-[rgba(255,255,255,0.1)] px-3 py-1 text-xs text-[rgba(255,255,255,0.8)]">
                          {isComingSoon ? 'Coming Soon' : 'Beta'}
                        </span>
                      </div>
                    )}
                  </div>
                  <p className="text-sm text-[rgba(255,255,255,.50)]">
                    {description}
                  </p>
                </li>
              );
            }
          )}
        </ul>

        <div className="value-props-gradient pointer-events-none" />
      </div>
    </Section>
  );
}

ValueProps.displayName = 'ValueProps';
ValueProps.Schema = Schema;
