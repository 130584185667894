export function PlayButton(props) {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="_icon-xs">
        <rect
          x="0.460052"
          y="0.680664"
          width="55.0799"
          height="55.0799"
          rx="12.6765"
          fill="currentColor"
        />
        <path
          id="Vector"
          d="M21.7271 20.8838L34.0738 28.2918L21.7271 35.6998V20.8838Z"
          fill="white"
          stroke="white"
          strokeWidth="2.46934"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}
