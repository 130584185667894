import { useEffect, useState } from 'react';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { MenuItems } from './menu-items';

export function DesktopMenu({ promobarVisibleHeight, settings }) {
  const [navItem, setNavItem] = useState('');

  useEffect(() => {
    const closeNav = () => {
      setNavItem('');
    };

    window.addEventListener('scroll', closeNav);
    return () => {
      window.removeEventListener('scroll', closeNav);
    };
  }, []);

  return (
    <NavigationMenu.Root value={navItem} onValueChange={setNavItem}>
      <NavigationMenu.List className="hidden items-center lg:-ml-3 lg:flex">
        {settings?.map((menuItem, index) => {
          const type = menuItem?._template;
          const title = menuItem?.title;
          const isActive = title === navItem;

          if (type === 'link') {
            return (
              <NavigationMenu.Item key={`desktopMenu-${index}`}>
                <NavigationMenu.Link
                  className={`special-2 px-3 py-1 leading-[18px] no-underline ${
                    navItem !== ''
                      ? isActive
                        ? 'text-[var(--text-primary)]'
                        : 'text-[var(--text-primary-light)]'
                      : 'text-[var(--text-primary)]'
                  }`}
                  href={menuItem?.link?.url}
                >
                  {title}
                </NavigationMenu.Link>
              </NavigationMenu.Item>
            );
          }

          if (type === 'menu') {
            const menuItems = menuItem?.megaMenuItems;
            return (
              <NavigationMenu.Item key={`desktopMenu-${index}`} value={title}>
                <NavigationMenu.Trigger
                  className={`special-2 px-3 py-1 leading-[18px] no-underline ${
                    navItem !== ''
                      ? isActive
                        ? 'text-[var(--text-primary)]'
                        : 'text-[var(--text-primary-light)]'
                      : 'text-[var(--text-primary)]'
                  }`}
                >
                  {title}
                </NavigationMenu.Trigger>

                <NavigationMenu.Content
                  style={{
                    top: `${promobarVisibleHeight + 65}px`,
                  }}
                  className="fixed left-0 right-0 z-20 w-full bg-black/90 backdrop-blur-[12.5px]"
                >
                  <div className="container mx-auto grid w-full grid-cols-4 gap-2 px-3 py-8">
                    <MenuItems
                      menuItems={menuItems}
                      closeNav={() => setNavItem('')}
                    />
                  </div>
                </NavigationMenu.Content>
              </NavigationMenu.Item>
            );
          }
          return null;
        })}
      </NavigationMenu.List>
    </NavigationMenu.Root>
  );
}
