import { IconSettings } from './icons';

export default {
  label: 'Header',
  name: 'header',
  component: 'group',
  description: 'Header menu for navigation and mobile menu',
  fields: [
    {
      label: 'Promobar',
      name: 'promobar',
      component: 'group',
      description: 'Enable, messages, colors, slider settings',
      fields: [
        {
          label: 'Enabled',
          name: 'enabled',
          component: 'toggle',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: true,
        },
        {
          label: 'Autohide',
          name: 'autohide',
          component: 'toggle',
          description:
            'Hides promobar after scrolling away from top of the page',
          toggleLabels: {
            true: 'On',
            false: 'Off',
          },
          defaultValue: false,
        },
        {
          label: 'Effect Between Transitions',
          name: 'effect',
          component: 'select',
          description: 'Refresh page to observe change',
          options: [
            {label: 'Fade', value: 'fade'},
            {label: 'Horizontal Slide', value: 'slide-horizontal'},
            {label: 'Vertical Slide', value: 'slide-vertical'},
          ],
          defaultValue: 'fade',
        },
        {
          label: 'Autoplay Delay',
          name: 'delay',
          component: 'number',
          description: 'Delay between transitions (in ms)',
          defaultValue: 5000,
        },
        {
          label: 'Speed',
          name: 'speed',
          component: 'number',
          description: 'Duration of transition between slides (in ms)',
          defaultValue: 500,
        },
        {
          label: 'Messages',
          name: 'messages',
          component: 'group-list',
          itemProps: {
            label: '{{item.message}}',
          },
          fields: [
            {
              label: 'Message',
              name: 'message',
              component: 'markdown',
            },
            {
              label: 'Link (optional)',
              name: 'link',
              component: 'link',
              description: 'Link wrapping entire message',
            },
          ],
          defaultItem: {
            message: 'Optimize your site for conversions ∙ **Learn more**',
            link: {url: '/', text: ''},
          },
        },
      ],
    },
    {
      label: 'Menu',
      name: 'menu',
      component: 'blocks',
      description: 'Menu links or mega menu navigation',
      templates: {
        menu: {
          label: 'Mega Menu',
          name: 'menuMega',
          itemProps: {
            label: '{{item.title}}',
          },
          fields: [
            {
              label: 'Title',
              name: 'title',
              component: 'text',
            },
            {
              label: 'Mega Menu Items',
              name: 'megaMenuItems',
              component: 'blocks',
              description: 'Menu item types for the mega menu',
              templates: {
                iconLinkList: {
                  label: 'Icon Link List',
                  name: 'iconLinkList',
                  itemProps: {
                    label: '{{item.title}}',
                  },
                  fields: [
                    {
                      component: 'text',
                      label: 'Title',
                      name: 'title',
                    },
                    {
                      component: 'group-list',
                      name: 'links',
                      label: 'Links',
                      itemProps: {
                        label: '{{item.title}}',
                      },
                      fields: [
                        IconSettings,
                        {
                          component: 'text',
                          name: 'title',
                          label: 'Title',
                        },
                        {
                          component: 'text',
                          name: 'description',
                          label: 'Description',
                        },
                        {
                          component: 'link',
                          name: 'link',
                          label: 'Link',
                        },
                      ],
                    },
                  ],
                },
                imageLink: {
                  label: 'Image Link',
                  name: 'imageLink',
                  itemProps: {
                    label: '{{item.title}}',
                  },
                  fields: [
                    {
                      component: 'text',
                      name: 'title',
                      label: 'Title',
                    },
                    {
                      component: 'image',
                      name: 'Image',
                      label: 'Image',
                    },
                    {
                      component: 'text',
                      name: 'subheading',
                      label: 'Sub Heading',
                    },
                    {
                      component: 'text',
                      name: 'description',
                      label: 'Description',
                    },
                    {
                      component: 'link',
                      name: 'link',
                      label: 'Link',
                    },
                  ],
                },
                linkList: {
                  label: 'Link List',
                  name: 'linkList',
                  itemProps: {
                    label: '{{item.title}}',
                  },
                  fields: [
                    {
                      component: 'text',
                      label: 'Title',
                      name: 'title',
                    },
                    {
                      component: 'group-list',
                      name: 'links',
                      label: 'Links',
                      itemProps: {
                        label: '{{item.title}}',
                      },
                      fields: [
                        {
                          component: 'text',
                          name: 'title',
                          label: 'Title',
                        },
                        {
                          component: 'link',
                          name: 'link',
                          label: 'Link',
                        },
                      ],
                    },
                  ],
                },
                spacer: {
                  label: 'Spacer',
                  name: 'spacer',
                },
              },
            },
          ],
        },
        link: {
          label: 'Link',
          name: 'menuLink',
          itemProps: {
            label: '{{item.title}}',
          },
          fields: [
            {
              label: 'Title',
              name: 'title',
              component: 'text',
            },
            {
              label: 'Link',
              name: 'link',
              component: 'link',
            },
          ],
        },
      },
    },
    {
      label: 'CTAs',
      name: 'ctas',
      component: 'group',
      description: 'Links for right side CTAs',
      fields: [
        {
          label: 'Get Started',
          name: 'getStarted',
          component: 'link',
          defaultValue: {
            text: 'Get Started',
          },
        },
        {
          label: 'Demo',
          name: 'demo',
          component: 'link',
          defaultValue: {
            text: 'Book a Demo',
          },
        },
        {
          label: 'Login',
          name: 'login',
          component: 'link',
          defaultValue: {
            text: 'Login',
          },
        },
      ],
    },
  ],
};
