import { useState } from 'react';
import { useRouter } from '@backpackjs/storefront';
import { Schema } from './EmailSignup.schema';

import { Link } from '../../snippets/Link';
import { Section } from '../../snippets';

export function EmailSignup({ cms }) {
  const router = useRouter();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const {
    formId,
    formRedirect,
    heading,
    description,
    label,
    policyText,
    policyLink,
  } = cms;

  function handleChange(e) {
    setEmail(e.target.value);
  }

  async function handleSubmit(e) {
    e.preventDefault();
    if (loading || !email) return;
    setLoading(true);
    try {
      const resp = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          formId,
        }),
      });
      const respJson = await resp.json();
      if (!respJson.success) throw new Error('Could not subscribe');
      setSuccess(true);
      if (formRedirect) {
        try {
          const redirectUrl = new URL(formRedirect);
          window.location = redirectUrl.href;
        } catch (err) {
          router.push(formRedirect);
        }
      }
      setTimeout(() => {
        setSuccess(false);
      }, 3000);
    } catch (err) {
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  }

  if (!formId) return null;

  return (
    <Section cms={cms}>
      <div className="py-12 md:py-24">
        <div className="mx-auto max-w-7xl px-4 md:px-8">
          <div className="black-green-white-gradient flex flex-col items-center gap-8 rounded-[20px] p-6 md:p-16">
            <div className="flex flex-col gap-3 text-white">
              {heading ? (
                <h2 className="text-center text-4xl font-medium">{heading}</h2>
              ) : null}
              {description ? (
                <p className="text-center text-xl text-white-700">
                  {description}
                </p>
              ) : null}
            </div>
            <form
              className="flex flex-col gap-3 max-md:w-full md:flex-row"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col gap-[6px]">
                <input
                  type="email"
                  className="input-email-white"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleChange}
                  required
                />
                {policyLink ? (
                  <p className=" text-2xs text-white md:text-sm">
                    {policyText}{' '}
                    <Link
                      className="underline"
                      href={policyLink.url}
                      newTab={policyLink?.newTab}
                    >
                      {policyLink.text}
                    </Link>
                    .
                  </p>
                ) : null}
              </div>
              <button
                type="submit"
                className="btn-primary text-white"
                disabled={loading}
              >
                {/* eslint-disable-next-line no-nested-ternary */}
                {loading ? 'Loading...' : success ? 'Subscribed' : label}
              </button>
            </form>
          </div>
        </div>
      </div>
    </Section>
  );
}

EmailSignup.displayName = 'EmailSignup';
EmailSignup.Schema = Schema;
