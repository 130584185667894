import { createRef, useState, useEffect, useRef } from 'react';
import { Schema } from './FeatureSteps.schema';
import { Icons } from '../../snippets/Icons';
import { FeatureStepsMain } from './FeatureStepsMain';
import { FeatureStepsNavItem } from './FeatureStepsNavItem';
import { Lotties } from '../../snippets/Lotties';

import { Section } from '../../snippets';

export function FeatureSteps({ cms }) {
  const { steps, ...mainProps } = cms;

  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [showNav, setShowNav] = useState(false);

  const stepsRefs = useRef(
    steps?.reduce((carry, _, index) => {
      return {
        ...carry,
        [index]: createRef(),
      };
    }, {}) || null
  );

  const navRef = useRef();
  const containerRef = useRef();

  useEffect(() => {
    const toggleNav = () => {
      const containerBottomPos =
        containerRef.current.getBoundingClientRect().height +
        containerRef.current.getBoundingClientRect().top;

      const navTopPos = navRef.current.getBoundingClientRect().top;
      const hideNav = window.innerHeight >= containerBottomPos;
      setShowNav(navTopPos === 0 && !hideNav);
    };

    const showActiveStep = () => {
      let activeIndex = null;
      Object.keys(stepsRefs.current)
        .reverse()
        .forEach((key) => {
          const step = stepsRefs.current[key];
          if (!step?.current || activeIndex) return;
          const stepTopPos = step.current.getBoundingClientRect().top;
          if (stepTopPos === 0) {
            activeIndex = key;
            return;
          }

          if (stepTopPos < 0) {
            activeIndex = key;
          }
        });
      setActiveStepIndex(activeIndex ? parseInt(activeIndex, 10) : 0);
    };

    const onScroll = () => {
      if (!stepsRefs?.current) return;
      toggleNav();
      showActiveStep();
    };

    ['resize', 'scroll'].forEach((event) => {
      window.addEventListener(event, onScroll);
    });
    return () => {
      ['resize', 'scroll'].forEach((event) => {
        window.removeEventListener(event, onScroll);
      });
    };
  }, []);

  return (
    <Section cms={cms}>
      <div className="bg-white">
        <FeatureStepsMain props={mainProps} />
        <div className="mx-auto max-w-7xl">
          <div
            ref={navRef}
            className={`px-contained sticky top-0 z-[2] hidden gap-2.5 pt-[100px] transition-opacity duration-300 lg:flex 2xl:px-0 ${
              showNav ? 'opacity-100' : 'opacity-0'
            }`}
            style={{
              marginBottom: 'calc(100vh - 140px)',
            }}
          >
            {steps?.map(({ stepName }, index) => {
              return (
                <FeatureStepsNavItem
                  activeStepIndex={activeStepIndex}
                  containerRef={containerRef}
                  index={index}
                  key={index}
                  stepName={stepName}
                  stepsRefs={stepsRefs}
                />
              );
            })}
          </div>

          <ul
            ref={containerRef}
            className="px-contained relative max-lg:py-12 lg:mt-[-100vh] 2xl:px-0"
          >
            {steps?.map(
              (
                {
                  heading,
                  description,
                  imageAlt,
                  image,
                  lottie,
                  listItems,
                  insights,
                },
                index
              ) => {
                return (
                  <li
                    className="top-0 transition-opacity duration-300 max-lg:px-4 max-lg:text-center max-lg:!opacity-100 lg:sticky"
                    ref={stepsRefs?.current[index]}
                    style={{
                      opacity: index === activeStepIndex ? 1 : 0,
                      pointerEvents:
                        index === activeStepIndex ? 'auto' : 'none',
                    }}
                  >
                    <div
                      className="grid grid-cols-1 items-center gap-x-12 text-black lg:h-screen lg:min-h-[600px] lg:grid-cols-2 lg:pt-[32.5px]"
                      key={index}
                    >
                      <div className="flex flex-col gap-y-6">
                        {heading && (
                          <h2 className="h2 md:text-[50px]">{heading}</h2>
                        )}
                        {description && (
                          <p className="text-[20px] md:text-[22px]">
                            {description}
                          </p>
                        )}

                        {listItems?.length > 0 && (
                          <ul className="flex flex-col gap-5 lg:gap-1">
                            {listItems.map((item, itemIndex) => {
                              return (
                                <li
                                  className="flex flex-col items-center gap-2 max-lg:px-4 lg:flex-row"
                                  key={itemIndex}
                                >
                                  {item.icon && (
                                    <Icons
                                      icon={item.icon}
                                      className="h-6 w-6 text-black"
                                    />
                                  )}
                                  <p className="text-[rgba(0,0,0,0.5)]">
                                    {item.description}
                                  </p>
                                </li>
                              );
                            })}
                          </ul>
                        )}

                        {insights?.length > 0 && (
                          <ul className="flex flex-wrap justify-center gap-5 max-lg:-mx-5 lg:justify-start lg:gap-12">
                            {insights.map((insight, insightsIndex) => {
                              return (
                                <div
                                  key={`insights-${insightsIndex}`}
                                  className="flex max-w-[135px] flex-col"
                                >
                                  <h3 className="h2 mb-2 whitespace-nowrap text-[40px] md:text-[44px]">
                                    {insight.heading}
                                  </h3>
                                  <p className="mb-3 whitespace-pre-line text-base lg:text-sm">
                                    {insight.description}
                                  </p>
                                </div>
                              );
                            })}
                          </ul>
                        )}
                      </div>

                      {lottie ? (
                        <div className="max-lg:-mx-5 max-lg:my-10">
                          {index === activeStepIndex && (
                            <Lotties
                              className="mx-auto overflow-hidden rounded-[30px] max-lg:max-w-[600px]"
                              lottie={lottie}
                              play
                              loop={false}
                            />
                          )}
                        </div>
                      ) : (
                        <picture className="max-lg:-mx-5 max-lg:my-10">
                          <img
                            className="mx-auto w-full overflow-hidden rounded-[30px] max-lg:max-w-[600px]"
                            src={image?.src}
                            alt={imageAlt}
                          />
                        </picture>
                      )}
                    </div>
                  </li>
                );
              }
            )}
            {/* extra child to show last step a little longer as your scroll down */}
            <li className="pointer-events-none sticky top-0 hidden lg:block">
              <div className="grid h-screen min-h-[600px] grid-cols-2 items-center gap-x-12 text-black lg:pt-[32.5px]" />
            </li>
          </ul>
        </div>
      </div>
    </Section>
  );
}

FeatureSteps.displayName = 'FeatureSteps';
FeatureSteps.Schema = Schema;
