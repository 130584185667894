import { useState, useEffect } from 'react';
import { useMediaPredicate } from 'react-media-hook';
import NextLink from 'next/link';

import { useSettings } from '@backpackjs/storefront';

import { Promobar } from './promobar';
import { DesktopMenu } from './desktop-menu';
import { DesktopRightMenu } from './desktop-right-menu';
import { MobileMenu } from './mobile-menu';
import { usePromobar } from '../../hooks';

import { LogoRebrandSquare } from '../../icons/logoRebrandSquare';
import { LogoColorRebrandSquare } from '../../icons/logoColorRebrandSquare';

export function Header() {
  const settings = useSettings();
  const {
    promobarAutoHide,
    promobarDisabled,
    promobarHidden,
    promobarVisibleHeight,
    promoBarRef,
  } = usePromobar();

  const [scrollPos, setScrollPos] = useState(0);
  const isDesktop = useMediaPredicate('(min-width: 1024px)');

  useEffect(() => {
    const onScroll = () => {
      const { scrollY } = window;
      const scrollValue = scrollY / 3;
      if (scrollValue >= 50) {
        setScrollPos(50);
      } else {
        setScrollPos(scrollValue);
      }
    };

    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return (
    <>
      <Promobar
        promobarAutoHide={promobarAutoHide}
        promobarDisabled={promobarDisabled}
        promobarHidden={promobarHidden}
        ref={promoBarRef}
      />
      <header
        className="px-contained sticky z-20 bg-black"
        style={{
          top: promobarAutoHide ? 0 : promobarVisibleHeight,
        }}
      >
        <div className="relative mx-auto flex h-[72px] max-w-7xl items-center justify-between lg:grid lg:h-[65px] lg:grid-cols-3">
          {/* Desktop Mega Menu */}
          <div className="hidden lg:flex">
            <DesktopMenu
              promobarVisibleHeight={promobarVisibleHeight}
              settings={settings?.header?.menu}
            />
          </div>

          <div className="lg:justify-self-center">
            <NextLink href="/" passHref>
              {/* Pack Logo  */}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="relative flex items-center gap-2">
                <span className="w-[105px]">
                  <LogoRebrandSquare
                    className={`transition-opacity duration-300 ${
                      scrollPos >= 50 ? 'opacity-0' : 'opacity-100'
                    }`}
                    style={{
                      transform: isDesktop
                        ? `translateX(calc(${scrollPos}% + ${
                            (scrollPos / 50) * 16
                          }px))`
                        : null,
                    }}
                  />
                  <LogoColorRebrandSquare
                    className={`absolute top-[0] left-[0] transition-opacity duration-300 ${
                      scrollPos >= 50 ? 'opacity-100' : 'opacity-0'
                    }`}
                    style={{
                      transform: isDesktop
                        ? `translateX(calc(${scrollPos}% + ${
                            (scrollPos / 50) * 16
                          }px))`
                        : null,
                    }}
                  />
                </span>
                <span
                  className="absolute right-0 font-aeonik text-[28px] tracking-[-0.706px]"
                  style={{
                    background: `linear-gradient(270deg, rgba(247, 247, 247, 0.80) 0%, rgba(247, 247, 247, 0) ${
                      ((50 - scrollPos) / 50) * 100
                    }%) text`,
                    WebkitTextFillColor: `rgba(247, 247, 247, ${
                      scrollPos > 10 ? '0' : '1'
                    }`,
                    transform: `translateX(-${scrollPos}%)`,
                  }}
                >
                  Pack
                </span>
              </a>
            </NextLink>
          </div>

          {/* Desktop Menu Right */}
          <DesktopRightMenu />

          <MobileMenu
            promobarVisibleHeight={promobarVisibleHeight}
            settings={settings?.header?.menu}
          />
        </div>
      </header>
    </>
  );
}
