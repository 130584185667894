import { IconSettings } from '../../settings/icons';

export function Schema() {
  return {
    label: 'Feature Timeline',
    key: 'feature-timeline',
    fields: [
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'Feature Timeline Heading',
      },
      {
        label: 'Items',
        name: 'items',
        component: 'group-list',
        itemProps: {
          label: '{{item.badge}}',
        },
        fields: [
          {
            label: 'Badge',
            name: 'badge',
            component: 'text',
          },
          {
            label: 'Heading',
            name: 'heading',
            component: 'text',
          },
          {
            label: 'Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Image Alt',
            name: 'imageAlt',
            component: 'text',
          },
          {
            label: 'Image Type',
            name: 'imageType',
            component: 'select',
            options: [
              {
                label: 'Center',
                value: 'center',
              },
              {
                label: 'To Edge',
                value: 'to-edge',
              },
            ],
            defaultValue: 'center',
          },
          {
            label: 'Description Heading',
            name: 'descriptionHeading',
            component: 'text',
          },
          {
            label: 'Description',
            name: 'description',
            component: 'textarea',
          },
          {
            label: 'List Heading',
            name: 'listHeading',
            component: 'text',
          },
          {
            label: 'List Items',
            name: 'listItems',
            component: 'group-list',
            itemProps: {
              label: '{{item.description}}',
            },
            fields: [
              IconSettings,
              {
                label: 'Description',
                name: 'description',
                component: 'textarea',
              },
            ],
            defaultItem: {
              description: 'List Item',
            },
          },
          {
            label: 'Inner Image Heading',
            name: 'innerImageHeading',
            component: 'text',
          },
          {
            label: 'Inner Image Mobile',
            name: 'innerImage',
            component: 'image',
          },
          {
            label: 'Inner Image Desktop',
            name: 'innerImageDesktop',
            component: 'image',
          },
          {
            label: 'Inner Image Alt',
            name: 'innerImageAlt',
            component: 'text',
          },
          {
            label: 'Insights Heading',
            name: 'insightsHeading',
            component: 'text',
          },
          {
            label: 'Insights',
            name: 'insights',
            component: 'group-list',
            fields: [
              {
                label: 'Heading',
                name: 'heading',
                component: 'text',
              },
              {
                label: 'Description',
                name: 'description',
                component: 'textarea',
              },
              {
                label: 'Bar Percentage',
                name: 'barPercentage',
                component: 'number',
              },
            ],
            itemProps: {
              label: '{{item.heading}}',
            },
            validate: {
              maxItems: 3,
            },
            defaultItem: {
              heading: '1x',
              description: 'Insight 1',
              barPercentage: 25,
            },
          },
        ],
        defaultItem: {
          badge: 'Step One',
        },
        defaultValue: [
          {
            badge: 'Step One',
            heading: 'Feature Timeline Item Heading',
          },
        ],
      },
      {
        label: 'Buttons',
        name: 'buttons',
        component: 'group-list',
        fields: [
          {
            label: 'Type',
            name: 'type',
            component: 'select',
            options: [
              { label: 'Primary', value: 'btn-primary' },
              { label: 'Outline', value: 'btn-outline' },
            ],
          },
          {
            label: 'Link',
            name: 'link',
            component: 'link',
          },
          IconSettings,
        ],
        itemProps: {
          label: '{{item.link.text}}',
        },
        validate: {
          maxItems: 2,
        },
        defaultItem: {
          type: 'btn-primary',
          link: { text: 'Get Started', url: '' },
          icon: '',
        },
        defaultValue: [
          {
            type: 'btn-primary',
            link: { text: 'Get Started', url: '' },
            icon: '',
          },
          {
            type: 'btn-outline',
            link: { text: 'Book a demo', url: '' },
            icon: 'clock',
          },
        ],
      },
    ],
  };
}
