import { IconSettings } from '../../settings/icons';
import { LottieSettings } from '../../settings/lotties';

export function Schema() {
  return {
    label: 'Feature Tabs',
    key: 'feature-tabs',
    fields: [
      {
        label: 'Superheading',
        name: 'superheading',
        component: 'text',
        defaultValue: 'Platform',
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'One place to build, publish and deploy.',
      },
      {
        label: 'Tabs',
        name: 'tabs',
        component: 'group-list',
        itemProps: {
          label: '{{item.label}}',
        },
        fields: [
          {
            label: 'Label',
            name: 'label',
            component: 'text',
          },
          {
            label: 'Description',
            name: 'description',
            component: 'markdown',
            description: 'Bolding any text will be rendered with white text',
          },
          IconSettings,
          LottieSettings,
          {
            label: 'Timer',
            name: 'timer',
            component: 'number',
            description: 'Number in seconds for how long tab should be active.',
            defaultValue: '5',
          },
          {
            label: 'Desktop Image',
            name: 'image',
            component: 'image',
          },
          {
            label: 'Full width desktop image?',
            name: 'fullWidth',
            component: 'toggle',
            defaultValue: false,
          },
          {
            label: 'Mobile Image',
            name: 'imageMobile',
            component: 'image',
          },
        ],
        defaultValue: [
          {
            label: 'Visual Headless CMS',
            icon: 'elementsPlus',
            timer: '5',
            fullWidth: false,
          },
          {
            label: 'Developer Tools',
            icon: 'terminal',
            timer: '5',
            fullWidth: false,
          },
          {
            label: 'CRO Tools',
            icon: 'dashboard',
            timer: '5',
            fullWidth: true,
          },
        ],
      },
      {
        label: 'Footer Heading',
        name: 'footerHeading',
        component: 'text',
        defaultValue: 'Integrates with your entire stack',
      },
      {
        label: 'Footer Link',
        name: 'footerLink',
        component: 'link',
        defaultValue: {
          text: 'See all integrations',
          url: '/',
        },
      },
      {
        label: 'Footer Background Image',
        name: 'footerBg',
        component: 'image',
      },
    ],
  };
}
