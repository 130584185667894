import React from 'react';
import { Icons } from '../../snippets/Icons';
import { FadeIn } from '../../animations/FadeIn';

export function FeatureTimelineItem({ index, item, items }) {
  return (
    <div
      className={`relative z-10 overflow-hidden pb-12 last:pb-0 md:flex md:justify-between md:pb-[60px] md:pl-20 ${
        item.imageType === 'center' ? 'md:pr-20' : ''
      }`}
    >
      {index === 0 && (
        <div className="absolute left-[28px] h-full border-l-2 border-brand md:left-20 md:top-3 md:w-10 md:rounded-tl-2xl md:border-t-2" />
      )}
      {index > 0 && index !== items?.length - 1 && (
        <div className="absolute left-[28px] h-full border-l-2 border-brand md:left-20 md:top-0 md:w-10 " />
      )}
      {index === items?.length - 1 && (
        <div className="absolute left-[28px] h-full w-0.5 bg-gradient-to-b from-brand md:left-20 md:top-0" />
      )}
      {index > 0 && (
        <div className="absolute border-brand md:left-20 md:top-3 md:w-10 md:border-t-2" />
      )}
      <div className="px-11 sm:min-w-[350px] md:max-w-xl md:px-16">
        <div className="absolute left-[17px] top-0 h-6 w-6 rounded-full border-[6px] border-primary-green-900 bg-brand md:left-[105px] md:h-7 md:w-7" />
        <div className="flex">
          <span className="badge-primary mb-[18px] px-3">{item.badge}</span>
        </div>

        {item.heading ? (
          <FadeIn>
            <h4 className="mb-3 font-aeonik text-[28px] md:text-[40px]">
              {item.heading}
            </h4>
          </FadeIn>
        ) : null}

        {item.descriptionHeading ? (
          <FadeIn>
            <h6 className="h6-em mb-8 font-medium text-brand md:mb-6">
              {item.descriptionHeading}
            </h6>
          </FadeIn>
        ) : null}

        {item.description ? (
          <FadeIn>
            <p className="mb-8 text-base md:text-lg md:text-white-600">
              {item.description}
            </p>
          </FadeIn>
        ) : null}

        {item.listHeading ? (
          <FadeIn>
            <h6 className="h6-em mb-8 font-medium text-brand md:mb-6">
              {item.listHeading}
            </h6>
          </FadeIn>
        ) : null}

        {item.listItems?.length > 0 && (
          <FadeIn>
            <ul className="mb-8">
              {item.listItems.map((listItem, listItemIndex) => {
                return (
                  <FadeIn delayAnimationSeconds={listItemIndex * 50}>
                    <li
                      key={`list-item-${listItemIndex}`}
                      className="mb-4 flex items-center gap-3 text-base last-of-type:mb-0 md:mb-2 md:text-lg md:text-white-600"
                    >
                      {listItem.icon ? (
                        <Icons
                          icon={listItem.icon}
                          className="h-6 w-6 text-brand"
                        />
                      ) : null}
                      {listItem.description}
                    </li>
                  </FadeIn>
                );
              })}
            </ul>
          </FadeIn>
        )}

        {item.innerImageHeading ? (
          <FadeIn>
            <h6 className="h6-em mb-8 font-medium text-brand md:mb-6">
              {item.innerImageHeading}
            </h6>
          </FadeIn>
        ) : null}

        {item.innerImage ? (
          <FadeIn>
            <div className="mb-8">
              <picture className="w-full">
                {item.innerImageDesktop && item.innerImage && (
                  <source
                    srcSet={item.innerImageDesktop.src}
                    media="(min-width: 768px)"
                  />
                )}
                <img
                  src={item.innerImage?.src || item.innerImageDesktop?.src}
                  alt={item.innerImageAlt}
                />
              </picture>
            </div>
          </FadeIn>
        ) : null}

        {item.insightsHeading ? (
          <FadeIn>
            <h6 className="h6-em mb-8 font-medium text-brand md:mb-6">
              {item.insightsHeading}
            </h6>
          </FadeIn>
        ) : null}

        {item.insights?.length > 0 && (
          <FadeIn>
            <div className="mx-auto mb-9 grid grid-cols-3 gap-6 md:order-2 md:mx-0 md:mb-[60px] md:flex lg:gap-[50px]">
              {item.insights.map((insight, insightsIndex) => {
                return (
                  <FadeIn delayAnimationSeconds={insightsIndex * 100}>
                    <div
                      key={`insights-${insightsIndex}`}
                      className="flex w-full max-w-[145px] flex-col"
                    >
                      <h4 className="mb-3 whitespace-nowrap text-[27px]">
                        {insight.heading}
                      </h4>
                      <p className="caption-1 mb-3 whitespace-pre-line md:text-white-600">
                        {insight.description}
                      </p>
                      <div className="progress-bar mt-auto h-[15px]">
                        <span
                          className="progress-bar-inner h-[15px]"
                          style={{
                            width: `${insight.barPercentage}%`,
                          }}
                        />
                      </div>
                    </div>
                  </FadeIn>
                );
              })}
            </div>
          </FadeIn>
        )}
      </div>

      {item.image ? (
        <div
          className={
            item.imageType === 'center'
              ? 'flex px-4 md:mx-auto md:w-80 md:shrink-0 lg:w-1/3 lg:max-w-[500px]'
              : 'flex pl-4 md:min-w-[400px]'
          }
        >
          <FadeIn
            mobileDirection={item.imageType === 'center' ? 'up' : 'left'}
            desktopDirection={item.imageType === 'center' ? 'up' : 'left'}
          >
            <img
              src={item.image.src}
              alt={item.imageAlt}
              className="relative z-20 w-full object-contain"
            />
          </FadeIn>
        </div>
      ) : null}
    </div>
  );
}

FeatureTimelineItem.displayName = 'FeatureTimelineItem';
