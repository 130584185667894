import { Icons } from '../../snippets/Icons';
import { Schema } from './FeatureImage.schema';
import { FadeIn } from '../../animations/FadeIn';

import { Link } from '../../snippets/Link';
import { Section } from '../../snippets';

export function FeatureImage({ cms }) {
  const { heading, subheading, imageAlt, imageMobile, imageDesktop, buttons } =
    cms;
  return (
    <Section cms={cms}>
      <div className="flex flex-col px-0 pt-16 pb-8 md:px-20 md:pt-[100px] md:pb-[60px]">
        <div className="mb-8 px-4 md:mb-[60px]">
          {heading && (
            <FadeIn>
              <h2 className="text-center selection:bg-brand">{heading}</h2>
            </FadeIn>
          )}
          {subheading && (
            <FadeIn>
              <p className="sub-heading mt-3 whitespace-pre-line text-center selection:bg-brand md:mt-5 md:whitespace-normal">
                {subheading}
              </p>
            </FadeIn>
          )}
        </div>
        {(imageDesktop || imageMobile) && (
          <div className="flex justify-center md:order-2">
            <FadeIn>
              <picture className="w-full">
                {imageDesktop && imageMobile && (
                  <source
                    srcSet={imageDesktop.src}
                    media="(min-width: 768px)"
                  />
                )}
                <img
                  className="w-full"
                  src={imageMobile?.src || imageDesktop?.src}
                  alt={imageAlt}
                />
              </picture>
            </FadeIn>
          </div>
        )}

        {buttons.length > 0 && (
          <div className="mt-9 flex flex-col-reverse gap-3 px-4 md:order-1 md:mt-0 md:mb-16 md:flex-row md:justify-center md:px-0">
            {buttons.map((button, index) => {
              return (
                <FadeIn delayAnimationSeconds={index * 50}>
                  <Link
                    className={button.type}
                    key={`featureImageButton-${index}`}
                    href={button.link?.url}
                    newTab={button.link?.newTab}
                  >
                    {button.icon && <Icons icon={button.icon} />}
                    {button.link.text}
                  </Link>
                </FadeIn>
              );
            })}
          </div>
        )}
      </div>
    </Section>
  );
}

FeatureImage.displayName = 'FeatureImage';
FeatureImage.Schema = Schema;
