import NextLink from 'next/link';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';
import { Icons } from '../Icons';

function Link({ isMobile, href, ...props }) {
  if (isMobile) {
    return <NextLink href={href} {...props} />;
  }

  return (
    <NextLink href={href} passHref legacyBehavior>
      <NavigationMenu.Link {...props} />
    </NextLink>
  );
}

export function MenuItems({ menuItems, isMobile }) {
  return menuItems?.map((subMenuItem) => {
    const {
      title: subMenuTitle,
      links,
      _template: subMenuType,
    } = subMenuItem || {};

    if (subMenuType === 'spacer') return <div />;

    if (subMenuType === 'imageLink') {
      const { description, subheading, Image, link } = subMenuItem;

      return (
        <div className="">
          <p
            className={`special-1 text-brand no-underline ${
              subMenuTitle ? 'mb-2 h-5' : ''
            }`}
          >
            {subMenuTitle}
          </p>

          <Link href={link?.url || '#'} isMobile={isMobile}>
            <a
              src={Image.src}
              alt={description}
              className="block aspect-video rounded-lg bg-cover bg-center bg-no-repeat"
              style={{
                backgroundImage: `url("${Image.src}")`,
              }}
            />
          </Link>

          <div className="py-3">
            <Link href={link?.url || '#'} isMobile={isMobile}>
              <a className="body-2-em">{subheading}</a>
            </Link>

            <p className="caption-2 text-white-600">{description}</p>
          </div>
        </div>
      );
    }

    if (subMenuType === 'linkList') {
      return (
        <div className="rounded-lg bg-[#0A0A0A] p-5">
          <p
            className={`special-1 text-brand no-underline ${
              subMenuTitle ? 'mb-2 h-5' : ''
            }`}
          >
            {subMenuTitle}
          </p>

          <ul className="space-y-3">
            {links?.map((item) => {
              return (
                <li>
                  <Link href={item?.link?.url || '#'} isMobile={isMobile}>
                    <a className="body-2 font-semibold text-white-600">
                      {item.title}
                    </a>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }

    if (subMenuType === 'iconLinkList') {
      return (
        <div>
          <p
            className={`special-1 text-brand no-underline ${
              subMenuTitle ? 'mb-2 h-5' : ''
            }`}
          >
            {subMenuTitle}
          </p>

          <ul className="space-y-1">
            {links?.map((item, index) => {
              const {
                icon,
                title: listItemTitle,
                description,
                link,
              } = item || {};

              return (
                <li key={index}>
                  <Link href={link?.url || '#'} isMobile={isMobile}>
                    <a className="flex items-center gap-4 px-3 py-2">
                      <Icons
                        icon={icon}
                        className="h-6 w-6 shrink-0 text-brand"
                      />
                      <div>
                        <p className="body-2-em">{listItemTitle}</p>
                        <p className="caption-2 text-white-600">
                          {description}
                        </p>
                      </div>
                    </a>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      );
    }

    return null;
  });
}
