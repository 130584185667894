import { useState } from 'react';

export function MarqueeComponent({
  logoMarquee,
  classes = '',
  imageClasses = 'mx-10 h-7',
  direction = 'normal',
}) {
  const [isHovered, setIsHovered] = useState(false);
  if (!logoMarquee?.length) return null;

  return (
    <div
      className={`relative flex w-full items-center overflow-x-hidden ${classes}`}
    >
      <div
        className="flex"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {[...Array(4).keys()].map((_, index) => {
          return (
            <div
              key={index}
              className="relative flex shrink-0 overflow-x-hidden"
              style={{
                animation: `scroll 80s infinite linear`,
                animationDirection: direction,
                animationPlayState: isHovered ? 'paused' : 'running',
              }}
            >
              {logoMarquee.map((marqueeLogo, index) => {
                return (
                  <MarqueeImage
                    marqueeLogo={marqueeLogo}
                    index={index}
                    imageClasses={imageClasses}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

MarqueeComponent.displayName = 'MarqueeComponent';

function MarqueeImage({ index, imageClasses, marqueeLogo }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <img
      key={index}
      className={`${imageClasses} transition-all ${isHovered ? 'invert' : ''}`}
      src={marqueeLogo?.imageLogo?.src}
      alt={marqueeLogo?.imageAlt}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    />
  );
}
