import { useState } from 'react';
import Expand from 'react-expand-animated';
import { Schema } from './Accordions.schema';
import { Icons } from '../../snippets/Icons';
import { Markdown } from '../../snippets/markdown';

import { Section } from '../../snippets';

export function Accordions({ cms }) {
  const { accordions, description, heading } = cms;

  return (
    <Section cms={cms}>
      <div className="px-4 py-16 md:py-24">
        {heading ? (
          <h4 className="mb-3 selection:bg-brand md:mx-auto md:mb-5 md:max-w-3xl md:text-center">
            {heading}
          </h4>
        ) : null}
        {description ? (
          <p className="sub-heading selection:bg-brand selection:text-white md:mx-auto md:max-w-3xl md:text-center md:text-white-700">
            {description}
          </p>
        ) : null}

        <ul className="mt-12 grid grid-cols-1 gap-6 md:mx-auto md:max-w-7xl md:px-8">
          {accordions?.map((accordion, index) => {
            return (
              <li
                key={index}
                className="border-b border-[rgba(255,_255,_255,_0.1)] pb-6 last-of-type:border-b-0 last-of-type:pb-0"
              >
                <Accordion accordion={accordion} />
              </li>
            );
          })}
        </ul>
      </div>
    </Section>
  );
}

function Accordion({ accordion }) {
  const { description, defaultOpen, header } = accordion;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  return (
    <div>
      <button
        aria-label={`${isOpen ? 'Close' : 'Open'} accordion for ${header}`}
        className="relative flex w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
        type="button"
      >
        <p className="body-1-em flex-1 pr-14 font-semibold">{header}</p>
        <div
          className={`absolute top-0 right-0 text-[#52615D] ${
            isOpen ? 'rotate-180' : ''
          }`}
        >
          <Icons icon="caretDown" />
        </div>
      </button>

      <Expand open={isOpen} duration={300}>
        <div className="mt-2 pr-14">
          <Markdown classes="caption-1 text-sm text-white-700 md:text-base">
            {description}
          </Markdown>
        </div>
      </Expand>
    </div>
  );
}

Accordions.displayName = 'Accordions';
Accordions.Schema = Schema;
